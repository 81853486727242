import { Button } from '../../shared/components/button/Button';
import { Input } from '../../shared/components/input/Input';
import './AuthPage.scss';
import { SignUpData } from './core/models';
import { signupThunk } from './core/thunks';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectCurrentAuthStatus,
    selectCurrentAuthStatusMessage,
} from './core/selectors';
import { ApiStatusType } from '../../app/api/apiStatusType';
import { useForm } from 'react-hook-form';
import { useApiStatus } from '../../shared/hooks/useApiStatus';
import { useState } from 'react';
import { Alert } from '../../shared/components/alert/Alert';

export const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const status = useAppSelector(selectCurrentAuthStatus);
    const message = useAppSelector(selectCurrentAuthStatusMessage);

    const [needToShowAlert, setNeedToShowAlert] = useState<boolean>(false);

    useApiStatus({
        status,
        handleSuccess: () => {
            navigate('/interviews/')
        },
        handleFailed: () => {
            setNeedToShowAlert(true);
            setTimeout(() => setNeedToShowAlert(false), 5000);
        },
    });

    const { register, handleSubmit } = useForm<SignUpData>();

    const onSubmit = (data: SignUpData) => {
        dispatch(signupThunk(data));
    };

    return (
        <>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Input placeholder="Ник" name="username" register={register} />
                <Input placeholder="Email" name="email" register={register} />
                <Input
                    type="password"
                    name="password"
                    placeholder="Пароль"
                    register={register}
                />
                <Button
                    type="submit"
                    styleType="positive"
                    style={{ marginTop: '8px' }}
                    disabled={status === ApiStatusType.PENDING}>
                    Зарегистрироваться
                </Button>
                <Button
                    type="button"
                    styleType="default"
                    onClick={() => navigate('/auth/login')}>
                    Войти
                </Button>
            </form>
            {needToShowAlert && (
                <Alert type="negative" message="Неправильные данные..." />
            )}
        </>
    );
};
